<template>
  <div>
    <b-card
      v-if="isPreDeclarationDone || h7Items.length > 0"
      ref="customs-info"
    >
      <b-card-title>
        <div class="float-left"><h4>{{ title }}</h4></div>
        <div class="float-right mb-1">
          <b-button
            v-b-modal.mark-done-modal
            variant="outline-success"
            @click="showMarkDoneConfirm = true"
          >
            Mark shipment as done
          </b-button>
        </div>
        <b-form-checkbox
          id="show-h7-statuses"
          v-model="showAllH7Statuses"
          switch
          class="float-right mx-2"
        >Show all H7 statuses</b-form-checkbox>
      </b-card-title>
      <confirm-modal
        id="mark-done-modal"
        :v-if="showMarkDoneConfirm"
        title="Are you sure you want to proceed?"
        confirmation="By proceeding the whole shipment will be marked as done."
        description="You can also mark parcels as 'manually cleared' by opening parcel detail or edit view and clicking 'Clear manually' under 'Parcel and declaration' section."
        @submit="markAsDone"
      />
      <b-table
        :fields="fields"
        :items="h7Items"
        thead-class="d-none"
        show-empty
      >

        <template #cell(name)="data">
          <router-link :to="data.item.link">
            <strong :class="data.item.class">{{ data.item.name }}</strong>
          </router-link>
        </template>

        <template #cell(count)="data">
          <router-link :to="data.item.link">
            <strong :class="data.item.class">{{ data.item.count }}</strong>
          </router-link>
        </template>

      </b-table>
    </b-card>
    <b-card
      v-if="isH1Allowed && (isPreDeclarationDone || h1Items.length > 0)"
      ref="customs-info"
    >
      <b-card-title>
        <div class="float-left mb-1"><h4>H1 customs information</h4></div>
        <b-form-checkbox
          id="show-h1-statuses"
          v-model="showAllH1Statuses"
          switch
          class="float-right mx-2"
        >Show all H1 statuses</b-form-checkbox>
      </b-card-title>
      <b-table
        :fields="fields"
        :items="h1Items"
        thead-class="d-none"
        show-empty
      >

        <template #cell(name)="data">
          <router-link :to="data.item.link">
            <strong :class="data.item.class">{{ data.item.name }}</strong>
          </router-link>
        </template>

        <template #cell(count)="data">
          <router-link :to="data.item.link">
            <strong :class="data.item.class">{{ data.item.count }}</strong>
          </router-link>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BFormCheckbox,
  BTable,
  BCardTitle,
} from 'bootstrap-vue'
import ConfirmModal from '@core/components/confirmation/confirmModal.vue'

export default {
  components: {
    BCardTitle,
    BFormCheckbox,
    ConfirmModal,
    BButton,
    BCard,
    BTable,
  },
  props: {
    shipmentId: {
      type: [String, Number],
      required: true,
    },
    isPreDeclarationDone: {
      type: Boolean,
      default: false,
    },
    isH1Allowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAllH1Statuses: false,
      showAllH7Statuses: false,
      title: 'H7 customs information',
      showMarkDoneConfirm: false,
      fields: [
        'name',
        {
          key: 'count',
          tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    h1Items() {
      const { statistics } = this.$attrs

      let statsRows = [
        {
          name: 'HS codes missing',
          link: `/shipments/${this.shipmentId}/missing-hs-codes-h1`,
          class: 'text-danger',
          count: statistics?.missingHsCodes?.h1 ?? 0,
        },
      ]

      if (this.isPreDeclarationDone) {
        statsRows = [
          ...statsRows,
          {
            name: 'Not sent to customs',
            link: `/shipments/${this.shipmentId}/declarations/not-sent-h1`,
            class: 'text-secondary',
            count: statistics?.notSent?.h1 ?? 0,
          },
          {
            name: 'Preparing to send',
            link: `/shipments/${this.shipmentId}/declarations/preparing-to-send-h1`,
            class: 'text-secondary',
            count: statistics?.preparingToSend?.h1 ?? 0,
          },
          {
            name: 'Waiting for customs',
            link: `/shipments/${this.shipmentId}/declarations/waiting-customs-h1`,
            class: 'text-secondary',
            count: statistics?.waitingForCustoms?.h1 ?? 0,
          },
          {
            name: 'Needs attention',
            link: `/shipments/${this.shipmentId}/declarations/needs-attention-h1`,
            class: 'text-warning',
            count: statistics?.needsAttention?.h1 ?? 0,
          },
          {
            name: 'Draft created in customs UI',
            link: `/shipments/${this.shipmentId}/declarations/draft-created-h1`,
            class: 'text-warning',
            count: statistics?.draftCreated?.h1 ?? 0,
          },
          {
            name: 'Cleared',
            link: `/shipments/${this.shipmentId}/declarations/cleared-h1`,
            class: 'text-success',
            count: statistics?.cleared?.h1 ?? 0,
          },
        ]
      }
      if (!this.showAllH1Statuses) {
        statsRows = statsRows.filter(x => x.count > 0)
      }

      return statsRows
    },
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    h7Items() {
      const { statistics } = this.$attrs

      let statsRows = [
        {
          name: 'Buyer country not allowed',
          link: `/shipments/${this.shipmentId}/declarations/buyer-country-not-allowed`,
          class: 'text-danger',
          count: statistics?.buyerCountryNotAllowed?.h7 ?? 0,
        },
        {
          name: 'Price over 150€ - H7 not available',
          link: `/shipments/${this.shipmentId}/declarations/price-over`,
          class: 'text-danger',
          count: statistics?.priceOver?.h7 ?? 0,
        },
        {
          name: 'Prohibited articles - H7 not available',
          link: `/shipments/${this.shipmentId}/declarations/prohibited`,
          class: 'text-danger',
          count: statistics?.prohibited?.h7 ?? 0,
        },
        {
          name: 'HS codes missing',
          link: `/shipments/${this.shipmentId}/missing-hs-codes`,
          class: 'text-danger',
          count: statistics?.missingHsCodes?.h7 ?? 0,
        },
        {
          name: 'Reduced VAT rate',
          link: `/shipments/${this.shipmentId}/declarations/reduced-vat`,
          class: 'text-danger',
          count: statistics?.reducedVat?.h7 ?? 0,
        },
        {
          name: 'Missing supplementary unit values',
          link: `/shipments/${this.shipmentId}/declarations/supplementary-unit`,
          class: 'text-warning',
          count: statistics?.supplementaryUnit?.h7 ?? 0,
        },
      ]

      if (this.isPreDeclarationDone) {
        statsRows = [
          ...statsRows,
          {
            name: 'Not sent to customs',
            link: `/shipments/${this.shipmentId}/declarations/not-sent`,
            class: 'text-secondary',
            count: statistics?.notSent?.h7 ?? 0,
          },
          {
            name: 'Resendables',
            link: `/shipments/${this.shipmentId}/declarations/resendables`,
            class: 'text-secondary',
            count: statistics?.resendables?.h7 ?? 0,
          },
          {
            name: 'Cancelled',
            link: `/shipments/${this.shipmentId}/declarations/cancelled`,
            class: 'text-secondary',
            count: statistics?.cancelled?.h7 ?? 0,
          },
          {
            name: 'Preparing to send',
            link: `/shipments/${this.shipmentId}/declarations/preparing-to-send`,
            class: 'text-secondary',
            count: statistics?.preparingToSend?.h7 ?? 0,
          },
          {
            name: 'Waiting for customs',
            link: `/shipments/${this.shipmentId}/declarations/waiting-customs`,
            class: 'text-secondary',
            count: statistics?.waitingForCustoms?.h7 ?? 0,
          },
          {
            name: 'Needs attention',
            link: `/shipments/${this.shipmentId}/declarations/needs-attention`,
            class: 'text-warning',
            count: statistics?.needsAttention?.h7 ?? 0,
          },
          {
            name: 'Held by customs',
            link: `/shipments/${this.shipmentId}/declarations/held`,
            class: 'text-warning',
            count: statistics?.heldByCustoms?.h7 ?? 0,
          },
          {
            name: 'Needs documents',
            link: `/shipments/${this.shipmentId}/declarations/needs-documents`,
            class: 'text-warning',
            count: statistics?.needsDocuments?.h7 ?? 0,
          },
          {
            name: 'Waiting for arrival',
            link: `/shipments/${this.shipmentId}/declarations/waiting-arrival`,
            class: 'text-secondary',
            count: statistics?.waitingForArrival?.h7 ?? 0,
          },
          {
            name: 'Cleared',
            link: `/shipments/${this.shipmentId}/declarations/cleared`,
            class: 'text-success',
            count: statistics?.cleared?.h7 ?? 0,
          },
        ]
      }
      if (!this.showAllH7Statuses) {
        statsRows = statsRows.filter(x => x.count > 0)
      }

      return statsRows
    },
  },
  methods: {
    markAsDone() {
      this.$bvModal.hide('mark-done-modal')
      this.$emit('done')
    },
  },
}
</script>

<style scoped>

</style>
